import React from "react";
import Navbar from "../component3/Navbar";
import Footer from "../component3/Footer";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Header from "../component3/Header";
import Box from '@mui/material/Box';
import ZoomedImageModal from "../component3/ZoomedImageModal";

export default function NacetemPartnersFutmina(){
    return<>
        <Navbar />
        <Header title="NEWS" />
         <Typography gutterBottom={3} variant="h4" sx={{pt:1,pl:1,textShadow:"1px 1px maroon"}}>
         Shaping the  <span style={{color:"gold"}}>Nigerian STI education landscape</span>: <br/>NACETEM partners FUTMinna, NIMR, & CT Consult
        </Typography>
        <Stack justifyContent='space-evenly' direction= {{xs:'column', sm:'column', md:"row-reverse"}} sx={{m:2, fontFamily: "roboto"}} spacing={2}>
        <Box sx={{width:{xs:"100%",sm:"100%",md:"50%"},display:"flex", flexWrap: 'wrap',justifyContent: 'space-evenly',alignItems: 'center', alignContent: 'center'}}>
        {["/pgd-accreditation1.jpg", "/pgd-accreditation2.jpg", "/pgd-accreditation3.jpg","/pgd-accreditation4.jpg"].map((imgSrc)=>
            <ZoomedImageModal
            displayText={
              <Box sx={{width:{xs:"100%",sm:"100%",md:"50%"}}}>
                  <img
                      src={imgSrc}
                      alt=""
                      style={{
                          width: "100%",
                          height: "auto",
                          objectFit: "contain",
                          borderRadius: 20,
                          marginBottom:5,
                          padding:5,
                          cursor:"help"
                  }}
                  />
              </Box>
            }
            imagePreviewSrc={imgSrc}
          />
            )}
        </Box>
        <Box sx={{width:{xs:"100%",sm:"100%",md:"50%"},mt:2}}>
            <Typography gutterBottom={2}>
                In a giant stride and momentous occasion, the National Centre for 
                Technology Management (NACETEM), an Agency of the Federal Ministry 
                of Innovation, Science & Technology (FMIST), signed a collaborative
                memorandum of understanding (MOU) with the Federal University of 
                Technology, Minna (FUTMinna) and Neuro-Linguistic Programming (NLP) Ltd. on 
                Thursday 23rd of November 2023.
            </Typography>
            <Typography gutterBottom={2}>
                The occasion, which took place at the FMIST (Federal Secretariat Complex, Abuja) 
                was witnessed by 
                the Honourable Minister of the FMIST,  Chief Uche Nnaji Nwakaibie.
            </Typography>
            <Typography gutterBottom={2}>
                The MOU effectively affiliates NACETEM's MSc and PGD (Technology Management Degree) programs with the University thereby strengthening their 
                legitimate and global status. NLP Ltd., a multinational management training organization, will add industry aligned cutting-edge training practices to the collaboration. The MOU leverages the  technical prowess of the three organisations with the aim of pushing the boundaries of STI education, skills and capacity advancements in the country.
            </Typography>
            <Typography gutterBottom={2}>
                The signing ceremony was attended by the DG/CEO of NACETEM, Dr. Olushola Odusanya; Vice Chancellor of FUTMinna, Professor Faruk Adamu Kuta; Country Director of NLP, Mr. Ranjiv Sharma; and several other dignitaries.
            </Typography>
            <Typography gutterBottom={2}>
                This is a step forward for NACETEM's postgraduate programs and the need for skilled professionals in Technology Management. New courses, such as Masters Degree in Nanotechnology, in Digital Marketing and Strategy, etc. have been added. Of importance is the fact that the MOU will enhance employability and improved profile of the program's graduates. This is a  bold move by NACETEM aimed at reforming the STI education landscape in Nigeria and positioning the programs better for meeting the evolving demands of the dynamic  
            </Typography>
        </Box>
        </Stack>
        <Footer/>
        </>
}