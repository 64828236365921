import React from "react";
import { Box } from "@mui/system";
import SelectCourse4EachCourseResults from "./SelectCourse4EachCourseResults";
import FilterCourse4Summary from "./FilterCourse4Summary";
import { API_URL } from "../utils/api";
import { useStateValue } from "../utils/app-state-context";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Grid from '@mui/material/Grid';
import Stack from "@mui/material/Stack";
import SelectStudent4Transcript from "./SelectStudent4Transcript";




export default function ResultsDashBoard() {
  const navigate = useNavigate();
  const [_, dispatch] = useStateValue();
  const [examResults, setExamResults] = React.useState();
  React.useEffect(() => {
    const retrieveExamResults = async () => {
      try {
        const response = await fetch(`${API_URL}/examResults`);
        if (!response.ok) {
          throw new Error(response.status);
        }
        const res = await response.json();
        setExamResults(res);
      } catch (error) {
        console.error("Error:", error);
      }
    };
    retrieveExamResults();
    dispatch({
      type: "POPULATERESULTS",
      payload: {
        results: examResults,
      },
    });
  }, [examResults, dispatch]);

  return (
    <>
      <Box sx={{ flexGrow: 1, m:2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <SelectCourse4EachCourseResults />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectStudent4Transcript />
        </Grid>
        <Grid item xs={12} md={6}>
          <FilterCourse4Summary />
        </Grid>
        <Grid item xs={12} md={6}>
            <Box
            sx={{
              p: 2,
              m: 2,
              borderRadius: 5,
              maxWidth: 500,
              borderBottom:"solid 2px",
              // background: "rgba(229,255,239)",
              // margin: "auto",
              // background:
              //   "linear-gradient(135deg, rgba(0,127,128,0.47942927170868344) 7%, rgba(118,148,216,0.4962359943977591) 35%, rgba(162,231,227,0.4990371148459384) 60%, rgba(229,255,239,1) 93%)",
            }}
          >
            
            <Stack spacing={4} direction="row">
            <h1 style={{ fontWeight: "bolder", fontSize: 22}}>
              Enter New Course Results
            </h1>
            <img src="/new-file-icon.png" alt=""/>
          </Stack>
            <Button
              variant="contained"
              color="primary"
              onClick={() =>{ navigate("/results-form");
              dispatch({
                type: "CANCELEDITMODE",
                payload: {
                  editMode: false,
                  editRecord: undefined
                },
              });}}
              style={{
                background: "#0C2340",
                paddingLeft: "40px",
                paddingRight: "40px",
                textTransform: "none",
              }}
            >
              Go
            </Button>
          </Box>
        </Grid>
        
      </Grid>
    </Box>
    </>
  );
}
