import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import { useStateValue } from "../utils/app-state-context";
import Stack from "@mui/material/Stack";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ResultsSummarysDialog, {
  useResultSummarysDialog,
} from "./ResultsSummaryDialog";

export default function FilterCourse4Summary() {
  const { openResultSummary, setOpenResultSummary } = useResultSummarysDialog();
  const [_, dispatch] = useStateValue();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [session, setSession] = React.useState("");
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSession = (session) => {
    setSession(session);
    setOpenResultSummary(true);
    setAnchorEl(null);
  };
  return (
    <Box
      sx={{
        p: 2,
        borderRadius: 5,
        maxWidth: 500,
        borderBottom: "solid 2px",
        // background: "rgba(229,255,239)",
        // margin: "auto",
        // background:
        //   "linear-gradient(135deg, rgba(0,127,128,0.47942927170868344) 7%, rgba(118,148,216,0.4962359943977591) 35%, rgba(162,231,227,0.4990371148459384) 60%, rgba(229,255,239,1) 93%)",
      }}
    >
      <ResultsSummarysDialog
        openResultSummary={openResultSummary}
        setOpenResultSummary={setOpenResultSummary}
        session={session}
      />

      <Stack spacing={4} direction="row" sx={{ mb: 2 }}>
        <h1 style={{ fontWeight: "bolder", fontSize: 20, marginBottom: 10 }}>
          View Course Results Summary
        </h1>
        <img src="/summary-icon.png" alt="" />
      </Stack>
      <Button
        variant="contained"
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
        style={{
          background: "#0C2340",
          paddingLeft: "40px",
          paddingRight: "40px",
          textTransform: "none",
        }}
      >
        Select Session
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={() => handleSession("2019-2020")}>
          2019-2020
        </MenuItem>
        <MenuItem onClick={() => handleSession("2021-2022")}>
          2021-2022
        </MenuItem>
        <MenuItem onClick={() => handleSession("2022-2023")}>
          2022-2023
        </MenuItem>
      </Menu>
    </Box>
  );
}
