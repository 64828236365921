import React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { grade } from "../utils/grade";
import Button from "@mui/material/Button";
import { useStateValue } from "../utils/app-state-context";
import { useNavigate } from "react-router-dom";

export default function ResultsTableRow({ results, centre }) {
  const [_, dispatch] = useStateValue();
  const navigate = useNavigate();
  const res = !centre
    ? results
    : results.filter((item) => item.centre === centre);

  const edit = (regNo) => {
    const res = results.filter((item) => item.regNo === regNo);
    dispatch({
      type: "EDIT-RESULT",
      payload: {
        editRecord: res[0],
        editMode: true,
      },
    });
    navigate("/results-form");
  };
  return (
    <>
      {res.length !== 0 ? (
        res.map((item, index) => (
          <TableRow
            key={item.id}
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
            }}
          >
            <TableCell component="th" scope="row">
              {index + 1}
            </TableCell>
            <TableCell align="left">{item.centre}</TableCell>
            <TableCell align="left">{item.session}</TableCell>
            <TableCell align="left">{item.regNo}</TableCell>
            <TableCell align="left">{item.score1}</TableCell>
            <TableCell align="left">{item.score2}</TableCell>
            <TableCell align="left">{item.score3}</TableCell>
            <TableCell align="left">{item.score4}</TableCell>
            <TableCell align="left">{item.score5}</TableCell>
            <TableCell align="left">{item.score6}</TableCell>
            <TableCell align="left">
              {Number(item.score1) +
                Number(item.score2) +
                Number(item.score3) +
                Number(item.score4) +
                Number(item.score5) +
                Number(item.score6)}
            </TableCell>
            <TableCell align="left">{item.ass}</TableCell>
            <TableCell align="left">{item.att}</TableCell>
            <TableCell align="left">{item.em}</TableCell>
            <TableCell align="left">
              {Number(item.score1) +
                Number(item.score2) +
                Number(item.score3) +
                Number(item.score4) +
                Number(item.score5) +
                Number(item.score6) +
                Number(item.ass) +
                Number(item.att) +
                Number(item.em)}
            </TableCell>
            <TableCell align="left">
              {grade(
                Number(item.score1) +
                  Number(item.score2) +
                  Number(item.score3) +
                  Number(item.score4) +
                  Number(item.score5) +
                  Number(item.score6) +
                  Number(item.ass) +
                  Number(item.att) +
                  Number(item.em)
              )}
            </TableCell>
            <TableCell align="left">
              <Button
                sx={{ textTransform: "none" }}
                onClick={() => edit(item.regNo)}
              >
                Edit
              </Button>
            </TableCell>
          </TableRow>
        ))
      ) : (
        <span style={{ margin: 10, fontWeight: "bold" }}>No Record</span>
      )}
    </>
  );
}
