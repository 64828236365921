import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { grade } from "../utils/grade";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export function useTranscriptDialog() {
  const [openTranscript, setOpenTranscript] = React.useState(false);
  return { openTranscript, setOpenTranscript };
}

export default function TranscriptDialog({
  openTranscript,
  setOpenTranscript,
  studentRecords,
}) {
  const handleClose = () => {
    setOpenTranscript(false);
  };
  const courses = {
    601: "Introduction to Science & Technology Policy",
    603: "Technology Strategy and Corporate Planning ",
    605: "Research Methodology and Statistics",
    607: "Technological Entrepreneurship",
    609: "Introduction to Techniques in Decision Making",
    602: "Introduction to Production & Operation Management",
    604: "Management of Research and Innovation System",
    606: "IIntroduction to Accounting and Engineering Economy",
    608: "Total Quality Management and New Games in Technology",
    610: "Fundamentals of Computer and Information Technology: Project",
  };
  return (
    <div>
      <BootstrapDialog
        fullScreen
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openTranscript}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          sx={{ fontSize: 12 }}
        >
          <span> STUDENT’S RECORDS AND ACADEMIC TRANSCRIPT</span>
          <br />
          <b>Name</b>: {studentRecords && studentRecords[0].regNo}
          <br />
          <b>Duration of Course</b>: One Year
          <br />
          <b>Course of Study</b>: PDG Technology Management
          <br /> <br />
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <p>First Semester {studentRecords && studentRecords[0].session}</p>
          <TableContainer component={Paper} sx={{ pb: 3 }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>SN</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Course Code</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    Course Title
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    Course Units
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Score</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Grade</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {studentRecords ? (
                  studentRecords
                    .filter((item) => item.semester === "First Semester")
                    .map((item, index) => (
                      <TableRow
                        key={item.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {index + 1}
                        </TableCell>
                        <TableCell align="left">
                          {" "}
                          TMD {item.courseCode}
                        </TableCell>
                        <TableCell align="left">
                          {courses[item.courseCode]}
                        </TableCell>
                        <TableCell align="left">2</TableCell>
                        <TableCell align="left">
                          {Number(item.score1) +
                            Number(item.score2) +
                            Number(item.score3) +
                            Number(item.score4) +
                            Number(item.score5) +
                            Number(item.score6) +
                            Number(item.ass) +
                            Number(item.att) +
                            Number(item.em)}
                        </TableCell>
                        <TableCell align="left">
                          {grade(
                            Number(item.score1) +
                              Number(item.score2) +
                              Number(item.score3) +
                              Number(item.score4) +
                              Number(item.score5) +
                              Number(item.score6) +
                              Number(item.ass) +
                              Number(item.att) +
                              Number(item.em)
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <span style={{ margin: 10, fontWeight: "bold" }}>
                    No Record
                  </span>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <p>Second Semester {studentRecords && studentRecords[0].session}</p>
          <TableContainer component={Paper} sx={{ pb: 3 }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>SN</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Course Code</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    Course Title
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    Course Units
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Score</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Grade</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {studentRecords ? (
                  studentRecords
                    .filter((item) => item.semester === "Second Semester")
                    .map((item, index) => (
                      <TableRow
                        key={item.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {index + 1}
                        </TableCell>
                        <TableCell align="left">
                          {" "}
                          TMD {item.courseCode}
                        </TableCell>
                        <TableCell align="left">
                          {courses[item.courseCode]}
                        </TableCell>
                        <TableCell align="left">2</TableCell>
                        <TableCell align="left">
                          {Number(item.score1) +
                            Number(item.score2) +
                            Number(item.score3) +
                            Number(item.score4) +
                            Number(item.score5) +
                            Number(item.score6) +
                            Number(item.ass) +
                            Number(item.att) +
                            Number(item.em)}
                        </TableCell>
                        <TableCell align="left">
                          {grade(
                            Number(item.score1) +
                              Number(item.score2) +
                              Number(item.score3) +
                              Number(item.score4) +
                              Number(item.score5) +
                              Number(item.score6) +
                              Number(item.ass) +
                              Number(item.att) +
                              Number(item.em)
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <span style={{ margin: 10, fontWeight: "bold" }}>
                    No Record
                  </span>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
