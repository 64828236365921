export default function Header({ title }) {
  return (
    <div
      className="py-8 md:py-16"
      style={{
        background:
          "linear-gradient(155deg, rgba(71,8,3,0.4) 15%, rgba(247,78,23,0.3) 39%, rgba(121,41,9,0.2) 60%, rgba(231,80,35,0.2) 78%, rgba(146,246,147,0.2) 100%)",
      }}
    >
      <div className="container mx-auto px-6">
        <h2 className="text-4xl font-bold mb-2 text-white">{title}</h2>
      </div>
    </div>
  );
}
