import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { API_URL } from "../utils/api";

export default function ResultsSummary() {
  const [rows, setRows] = React.useState(null);

  const courseGrades = (regNo, session, semester) =>
    rows
      .filter(
        (item) =>
          item.regNo === regNo &&
          item.session === session &&
          item.semester === semester
      )
      .map((item) => (
        <div>
          <span>{item.courseCode}</span>
          <br />
          <span>{item.regNo}</span>
          <br />
          <span>
            {Number(item.score1) +
              Number(item.score2) +
              Number(item.score3) +
              Number(item.score4) +
              Number(item.score5) +
              Number(item.score6) +
              Number(item.ass) +
              Number(item.att) +
              Number(item.em)}
          </span>
        </div>
      ));

  React.useEffect(() => {
    const retrieveExamRes = async () => {
      try {
        const response = await fetch(`${API_URL}/examResults`);
        if (!response.ok) {
          throw new Error(response.status);
        }
        const res = await response.json();
        setRows(res);
      } catch (e) {}
    };
    retrieveExamRes();
  }, [rows]);
  const regNoLst = rows.map(({ regNo }) => regNo);

  return (
    <TableContainer
      component={Paper}
      sx={{ background: "rgba(229,255,239,1)", pb: 3 }}
    >
      <Table aria-label="Results Table">
        <TableHead>
          <TableRow>
            {" "}
            <TableCell sx={{ fontWeight: "bold" }}>RegNo</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Exam Score</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {regNoLst.length !== 0
            ? [...new Set(regNoLst)].map((row) => (
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="left">{row}</TableCell>

                  <TableCell align="left">Later</TableCell>
                </TableRow>
              ))
            : "No Record"}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
