import * as React from "react";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { Select } from "formik-mui";
import LinearProgress from "@mui/material/LinearProgress";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import EachCourseResultsDialog, {
  useEachCourseResultsDialog,
} from "./EachCourseResultsDialog";
import { useStateValue } from "../utils/app-state-context";


export default function SelectCourse4EachCourseResults() {
  const [_, dispatch] = useStateValue();
  const { openResult, setOpenResult } = useEachCourseResultsDialog();
  const courses = [
    "601: INTRO. TO SCIENCE & TECHNOLOGY POLICY",
    "603: TECHNOLOGY STRATEGY AND CORPORATE PLANNING",
    "605: RESEARCH METHODOLOGY AND STATISTICS",
    "609: INTRO. TO QUANTITATIVE TECHNIQUES IN DECISION MAKING",
    "602: INTRO. TO PRODUCTION & OPERATION MANAGEMENT",
    "604: MANAGEMENT OF R&D AND INNOVATION SYSTEM",
    "606: INTRO. TO ACCOUNTING & ENGINEERING ECONOMY",
    "608: TOTAL QUALITY MANAGEMENT & NEW GAMES IN TECHNOLOGY",
    "610: FUNDAMENTALS OF COMPUTER & INFORMATION TECHNOLOGY: PROJECT",
  ];
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        courseCode: "",
        session: "",
      }}
      validationSchema={Yup.object({
        courseCode: Yup.string().required("*Please Select COurse"),
        // session: Yup.string().required("*Email is required"),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        setOpenResult(true);
        // alert(JSON.stringify(values));
        setSubmitting(false);
        //   courseCode: values.courseCode,
        //   privatePublic: values.privatePublic,
      }}
    >
      {({ submitForm, isSubmitting, values, handleChange, setFieldValue }) => (
        <Form>
          <EachCourseResultsDialog
            openResult={openResult}
            setOpenResult={setOpenResult}
          />

          <Box
            sx={{
              p: 2,
              borderRadius: 5,
              maxWidth: 500,
              borderBottom:"solid 2px"
              
              // background: "rgba(229,255,239)",
              // margin: "auto",
              // background:
              //   "linear-gradient(135deg, rgba(0,127,128,0.47942927170868344) 7%, rgba(118,148,216,0.4962359943977591) 35%, rgba(162,231,227,0.4990371148459384) 60%, rgba(229,255,239,1) 93%)",
            }}
          >
           <Stack spacing={4} direction="row" sx={{mb:2}}>
            <h1
              style={{ fontWeight: "bolder", fontSize: 22, marginBottom: 30 }}
            >
              View / Edit Each Course Results

            </h1>
            <img src="/results-icon.png" alt=""/>
           </Stack>
            
            {isSubmitting && <LinearProgress sx={{ my: 2 }} />}
            <FormControl fullWidth>
              <Field
                sx={{ mb: 3 }}
                component={Select}
                name="courseCode"
                size="small"
                label="Select Course"
                variant="outlined"
              >
                {courses.map((item) => (
                  <MenuItem
                    value={item}
                    onClick={() =>
                      dispatch({
                        type: "COURSECODE",
                        payload: {
                          courseCode: item.substring(0, 3),
                        },
                      })
                    }
                  >
                    {item}
                  </MenuItem>
                ))}
              </Field>
            </FormControl>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              //   justifyContent="center"
            >
              <FormControl fullWidth>
                <Field
                  sx={{ mb: 1 }}
                  component={Select}
                  name="session"
                  size="small"
                  label="Select Session"
                  variant="outlined"
                >
                  <MenuItem
                    value={"Ile Ife"}
                    onClick={() =>
                      dispatch({
                        type: "SESSION",
                        payload: {
                          session: "2019-2020",
                        },
                      })
                    }
                  >
                    2019-2020
                  </MenuItem>
                  <MenuItem
                    value={"Ile Ife"}
                    onClick={() =>
                      dispatch({
                        type: "SESSION",
                        payload: {
                          session: "2022-2023",
                        },
                      })
                    }
                  >
                    2022-2023
                  </MenuItem>
                  <MenuItem
                    value={"Lagos"}
                    onClick={() =>
                      dispatch({
                        type: "SESSION",
                        payload: {
                          session: "2021-2022",
                        },
                      })
                    }
                  >
                    2021-2022
                  </MenuItem>
                  <MenuItem
                    value={""}
                    onClick={() =>
                      dispatch({
                        type: "SESSION",
                        payload: {
                          session: "",
                        },
                      })
                    }
                    sx={{ color: "maroon" }}
                  >
                    Cancel Session
                  </MenuItem>
                </Field>
              </FormControl>
              {/* <Tooltip title="Cancel session">
                <Button
                  style={{ display: "inline" }}
                  onClick={() => {
                    setFieldValue("session", "");
                    // setSession("");
                  }}
                >
                  X
                </Button>
              </Tooltip> */}
            </Stack>
            <Stack
              direction="row"
              spacing={2}
              //   alignItems="center"
              //   justifyContent="center"
            >
              <Button
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onClick={submitForm}
                style={{
                  marginTop: 10,
                  background: "#0C2340",
                  paddingLeft: "40px",
                  paddingRight: "40px",
                  textTransform: "none",
                }}
              >
                {isSubmitting ? (
                  <span style={{ color: "yellow" }}>... submitting</span>
                ) : (
                  "Go"
                )}
              </Button>
            </Stack>
          </Box>
        </Form>
      )}
    </Formik>
  );
}
