import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Link } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import PublicationsMenuBar from "./PublicationsMenuBar";
import NewsDropDownMenu from "./NewsDropDownMenu"

export default function MobileMenuBar() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (
    <Box
      sx={{ width: "auto", fontFamily: "roboto" }}
      role="presentation"
      onClick={toggleDrawer("left", false)}
      onKeyDown={toggleDrawer("left", false)}
    >
      <List>
        <ListItem disablePadding>
          <ListItemButton>
            <Link style={{ color: "maroon" }} to="/">
              Home
            </Link>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <Link style={{ color: "maroon" }} to="/about">
              About
            </Link>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <Link style={{ color: "maroon" }} to="/research">
              Research
            </Link>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <Link style={{ color: "maroon" }} to="/capacity-building">
              Capacity Building
            </Link>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <Link style={{ color: "maroon" }} to="/psrtest">
              PSR Test
            </Link>
          </ListItemButton>
        </ListItem>
        {/* <ListItem disablePadding>
          <ListItemButton>
            <a style={{ color: "maroon" }} href="https://stinigeria.com/sti-indicator/">
              STI Indicator Dashboard
            </a>
          </ListItemButton>
        </ListItem> */}
        <ListItem disablePadding>
          <ListItemButton>
            <PublicationsMenuBar />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <NewsDropDownMenu />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <Link style={{ color: "maroon" }} to="/contact">
              Contact
            </Link>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment>
        <IconButton
          sx={{ color: "white" }}
          onClick={toggleDrawer("right", true)}
        >
          <MenuIcon />
        </IconButton>
        <SwipeableDrawer
          anchor={"right"}
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
          onOpen={toggleDrawer("right", true)}
        >
          {list}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
