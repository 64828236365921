import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FilterByCentre from "./FilterByCentre";
import ResultsTableRow from "./ResultsTableRow";
import { useStateValue } from "../utils/app-state-context";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const [_, dispatch] = useStateValue();
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={() => {
            onClose();
            dispatch({
              type: "CENTRE",
              payload: {
                centre: "",
              },
            });
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export function useEachCourseResultsDialog() {
  const [openResult, setOpenResult] = React.useState(false);
  return { openResult, setOpenResult };
}

export default function EachCourseResultsDialog({ openResult, setOpenResult }) {
  const handleClose = () => {
    setOpenResult(false);
  };
  const [{ courseCode, session, centre, results }, _] = useStateValue();
  const [res, SetRes] = React.useState();
  React.useEffect(() => {
    const response = results
      ? results.filter((item) =>
          session && centre
            ? item.courseCode === courseCode &&
              item.session === session &&
              item.centre === centre
            : !session && centre
            ? item.courseCode === courseCode && item.centre === centre
            : session && !centre
            ? item.courseCode === courseCode && item.session === session
            : item.courseCode === courseCode
        )
      : "";
    SetRes(response);
  }, [courseCode, session, centre, results, res]);
  const firstRec = res && res[0];

  return (
    <div>
      <BootstrapDialog
        fullScreen
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openResult}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {firstRec &&
            `${session ? firstRec["session"] : ""} ${
              centre && firstRec["centre"]
            } Centre ${firstRec["semester"]} Examination Results ${
              firstRec["courseCode"]
            }`}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <p>
            <FilterByCentre /> {centre && `Results for ${centre}`}
          </p>
          <TableContainer component={Paper} sx={{ pb: 3 }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>SN</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Centre</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Session</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Name</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Score1</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Score2</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Score3</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Score4</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Score5</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Score6</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Exam</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Ass</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Att</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Em</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Total</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Grade</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Edit</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <ResultsTableRow results={res} />
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
