import React from "react";
import Navbar from "../component3/Navbar";
import Footer from "../component3/Footer";
import "../App.css";
const AdvertWorksAndGoods = () => {
  return (
    <>
      <Navbar />
      <p style={{ marginTop: 80 }}></p>
      <iframe
        src={"/Advert_Works_&_Goods_2023_Projects.pdf#view=fitH"}
        title="Advert_Works_&_Goods_2023_Projects"
        width="100%"
        height="500px"
      />
      <Footer />
    </>
  );
};
export default AdvertWorksAndGoods;
