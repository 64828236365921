import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

export default function LoadingSkeleton() {
  return (
    <Stack spacing={3} alignContent="center">
      <Stack direction="row" alignItems="center">
        <Skeleton 
        animation="wave" 
        variant="rounded" 
        width={60} 
        height={60} 
        sx={{m:3}}
         />
        <Skeleton
              animation="wave"
              height={30}
              width="40%"
              sx={{mr:4}}
            />
        {[1,2,3,4,5,6].map(()=><Skeleton
              animation="wave"
              height={30}
              width="5%"
              sx={{m:1}}
            />)}
      </Stack>
      <Box sx={{ width: 400, m:6 }}>
        <Skeleton animation="wave" sx={{m:4}}/>
        <Skeleton animation="wave" sx={{m:4}}/>
        <Skeleton animation="wave" sx={{m:4}} />
      </Box>
      <Stack direction="row" justifyContent="space-around" alignItems="center" sx={{width:"100%", margin:"auto"}}>
        <Skeleton variant="circular" width={50} height={50} sx={{m:1}} />
        <Skeleton variant="circular" width={50} height={50} sx={{m:1}} />
        <Skeleton variant="circular" width={50} height={50} sx={{m:1}} />
      </Stack>
      <Skeleton sx={{ height: 190,mb:5 }} animation="wave" variant="rectangular" />
      <Skeleton sx={{ height: 190,mb:5 }} animation="wave" variant="rectangular" />

    </Stack>
  );
}
