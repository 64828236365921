import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { useStateValue } from "../utils/app-state-context";

export default function FilterByCentre() {
  const [_, dispatch] = useStateValue();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleFilter = (centre) => {
    dispatch({
      type: "CENTRE",
      payload: {
        centre,
      },
    });
    setAnchorEl(null);
    // alert(JSON.stringify(filterBy(centre)));
  };
  // const filterBy = (centre) => results.filter((item) => item.centre === centre);
  return (
    <div>
      <IconButton
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Tooltip title="Filter by Centre">
          <MenuIcon />
        </Tooltip>
      </IconButton>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={() => handleFilter("Ile Ife")}>Ile Ife</MenuItem>
        <MenuItem onClick={() => handleFilter("Lagos")}>Lagos</MenuItem>
        <MenuItem onClick={() => handleFilter("Abuja")}>Abuja</MenuItem>
        <MenuItem onClick={() => handleFilter("")} style={{ color: "maroon" }}>
          Cancel Filter
        </MenuItem>
      </Menu>
    </div>
  );
}
