export const grade = (score) => {
  if (score >= 70) {
    return "A";
  } else if (score >= 60 && score <= 69) {
    return "B+";
  } else if (score >= 50 && score <= 59) {
    return "B";
  } else if (score >= 45 && score <= 49) {
    return "C";
  } else {
    return "F";
  }
};
// A	=	70%	and	above
// B+	=	60%	  –	 69%
// B 	=	50%	  –	 59%
// C 	=	45%	  –	 49%
// F	=	40%	  –	 44%
