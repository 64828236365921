import * as React from "react";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { Select } from "formik-mui";
import LinearProgress from "@mui/material/LinearProgress";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import TranscriptDialog, { useTranscriptDialog } from "./TranscriptDialog";
import { useStateValue } from "../utils/app-state-context";

export default function SelectStudent4Transcript() {
  const [{ results }, dispatch] = useStateValue();
  const { openTranscript, setOpenTranscript } = useTranscriptDialog();
  const mayokun = (num) => (num ? Number(num) : 0);
  const list4Session = (session) =>
    results &&
    results
      .filter((item) => item.session === session)
      .map(({ regNo }) => regNo);
  const studentRecords = (session, regNo) =>
    results.filter((item) => item.session === session && item.regNo === regNo);
  // console.log(studentRecords("2019-2020"));
  const [stdRecord, setStdRecord] = React.useState();
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        courseCode: "",
        session: "",
      }}
      validationSchema={Yup.object({
        courseCode: Yup.string().required("*Please select a student name"),
        session: Yup.string().required("*Please select a session"),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        setOpenTranscript(true);
      }}
    >
      {({ submitForm, isSubmitting, values, handleChange, setFieldValue }) => (
        <Form>
          <TranscriptDialog
            openTranscript={openTranscript}
            setOpenTranscript={setOpenTranscript}
            studentRecords={stdRecord}
          />

          <Box
            sx={{
              p: 2,
              borderRadius: 5,
              maxWidth: 500,
              borderBottom:"solid 2px"
              // background: "rgba(229,255,239)",
              // margin: "auto",
              // background:
              //   "linear-gradient(135deg, rgba(0,127,128,0.47942927170868344) 7%, rgba(118,148,216,0.4962359943977591) 35%, rgba(162,231,227,0.4990371148459384) 60%, rgba(229,255,239,1) 93%)",
            }}
          >
            {" "}
            
            <Stack spacing={4} direction="row" sx={{mb:2}}>
            <h1
              style={{ fontWeight: "bolder", fontSize: 22, marginBottom: 30 }}
            >
              Generate Transcript
            </h1>
            <img src="/process-accept-icon.png" alt=""/>
          </Stack>
            {isSubmitting && <LinearProgress sx={{ my: 2 }} />}
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              //   justifyContent="center"
            >
              <FormControl fullWidth>
                <Field
                  sx={{ mb: 1 }}
                  component={Select}
                  name="session"
                  size="small"
                  label="Select Session"
                  variant="outlined"
                >
                  <MenuItem value={"2022-2023"}>2022-2023</MenuItem>
                  <MenuItem value={"2019-2020"}>2019-2020</MenuItem>
                  <MenuItem
                    value={""}
                    onClick={() =>
                      dispatch({
                        type: "SESSION",
                        payload: {
                          session: "",
                        },
                      })
                    }
                    sx={{ color: "maroon" }}
                  >
                    Cancel Session
                  </MenuItem>
                </Field>
              </FormControl>

              {/* <Tooltip title="Cancel session">
                <Button
                  style={{ display: "inline" }}
                  onClick={() => {
                    setFieldValue("session", "");
                    // setSession("");
                  }}
                >
                  X
                </Button>
              </Tooltip> */}
            </Stack>
            <FormControl fullWidth>
              <Field
                sx={{ mb: 2 }}
                component={Select}
                name="courseCode"
                size="small"
                label="Select Student"
                variant="outlined"
              >
                {list4Session &&
                  [...new Set(list4Session(values.session))].map((regNo) => (
                    <MenuItem
                      value={regNo}
                      onClick={() =>
                        setStdRecord(studentRecords(values.session, regNo))
                      }
                    >
                      {regNo}
                    </MenuItem>
                  ))}
              </Field>
            </FormControl>
            <Stack
              direction="row"
             
              //   alignItems="center"
              //   justifyContent="center"
            >
              <Button
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onClick={submitForm}
                style={{
                  background: "#0C2340",
                  paddingLeft: "40px",
                  paddingRight: "40px",
                  textTransform: "none",
                }}
              >
                {isSubmitting ? (
                  <span style={{ color: "yellow" }}>... submitting</span>
                ) : (
                  "Go"
                )}
              </Button>
            </Stack>
          </Box>
        </Form>
      )}
    </Formik>
  );
}
