import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { NavLink } from "react-router-dom";
import PublicationsMenuBar from "./PublicationsMenuBar";
import MobileMenuBar from "./MobileMenuBar";
import NewsDropDownMenu from "./NewsDropDownMenu"
import "../site-style.css";

export default function Navbar({isHome=true}) {
  return (
    <Box
      sx={{
        p: 2,
        width: "100%",
        display: "flex",
        background: "maroon",
        justifyContent: "space-between",
        alignContent: "center",
        alignItems: "center",
        fontFamily: "roboto",
      }}
    >
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={2}
        alignItems="center"
      >
        <Box
          sx={{
            display: { xs: "flex", sm: "flex" },
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <NavLink to="/">
            <img
              src="/logo.png"
              alt=""
              style={{
                width: 60,
                height: 60,
                background: "white",
                padding: 2,
                borderRadius: 10,
              }}
            />
          </NavLink>
          <NavLink to="/">
            <Box
              sx={{
                ml: 2,
                color: "white",
                fontSize: 28,
                display: { xs: "flex", sm: "flex", md: "none", letterSpacing: 2 },
              }}
            >
              NACETEM
            </Box>
          </NavLink>
        </Box>

        {/* sx={{display:{xs: "none", sm:"none", md:"flex"}}} */}
        <Stack
          alignItems="center"
          sx={{
            color: "white",
            fontSize: 20,
            display: { xs: "none", sm: "none", md: "flex" },
          }}
        >
          <NavLink to="/">
            <span>National Centre for Technology Management (NACETEM)</span>
          </NavLink>
        </Stack>
      </Stack>
      <Box
        sx={{
          display: { xs: "none", sm: "none", md: "none", lg: "flex" },
          width: "60%",
          justifyContent: "space-evenly",
          // color: "maroon",
          // "&:hover": {
          //   color: "gold",
          // },
          fontSize: 16,
        }}
      >
        <span className="nav-text">
          {isHome && <NavLink to="/">Home</NavLink>}
        </span>
        <span className="nav-text">
          <NavLink to="/about">About</NavLink>
        </span>
        <span className="nav-text">
          <NavLink to="/research">Research</NavLink>
        </span>
        <span className="nav-text">
          <NavLink to="/capacity-building">Capacity Building</NavLink>
        </span>
        {/* <span className="nav-text">
          <a href="https://stinigeria.com/sti-indicator/">STI Indicator Dashboard</a>
        </span> */}
        <span className="nav-text">
          <NavLink to="/psrtest">PSR Tests</NavLink>
        </span>
        <span className="nav-text">
          <PublicationsMenuBar />
        </span>
        <span className="nav-text">
          <NewsDropDownMenu/>
          {/* <NavLink to="/news/nacetem-partners-futmina">News</NavLink> */}
        </span>
        <span className="nav-text">
          <NavLink to="/contact">Contact</NavLink>
        </span>
      </Box>
      <Box sx={{ display: { xs: "flex", sm: "flex", md: "flex", lg: "none" } }}>
        <MobileMenuBar />
      </Box>
    </Box>
  );
}
// direction="row"
// spacing={6}
// direction={{ xs: "column", sm: "row" }}
// spacing={{ xs: 1, sm: 2, md: 4 }}
