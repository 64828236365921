import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useStateValue } from "../utils/app-state-context";
import { API_URL } from "../utils/api";
import { grade } from "../utils/grade";
import Stack from "@mui/material/Stack";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const [_, dispatch] = useStateValue();
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={() => {
            onClose();
            dispatch({
              type: "CENTRE",
              payload: {
                centre: "",
              },
            });
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export function useResultSummarysDialog() {
  const [openResultSummary, setOpenResultSummary] = React.useState(false);
  return { openResultSummary, setOpenResultSummary };
}

export default function ResultsSummarysDialog({
  openResultSummary,
  setOpenResultSummary,
  session,
}) {
  const handleClose = () => {
    setOpenResultSummary(false);
  };
  const [rows, setRows] = React.useState(null);
  const mayokun = (num) => (num ? Number(num) : 0);

  const courseGrades = (regNo, session, semester) =>
    rows
      .filter(
        (item) =>
          item.regNo === regNo &&
          item.session === session &&
          item.semester.trim() === semester.trim()
      )
      .map((item) => (
        <div style={{ float: "left" }}>
          <span>{item.courseCode}</span>
          <br />
          <span>{item.semester}</span>
          <br />
          <span>
            {Number(item.score1) +
              Number(item.score2) +
              Number(item.score3) +
              Number(item.score4) +
              Number(item.score5) +
              Number(item.score6) +
              Number(item.ass) +
              Number(item.att) +
              Number(item.em)}{" "}
            &nbsp;
            {grade(
              Number(item.score1) +
                Number(item.score2) +
                Number(item.score3) +
                Number(item.score4) +
                Number(item.score5) +
                Number(item.score6) +
                Number(item.ass) +
                Number(item.att) +
                Number(item.em)
            )}
          </span>
        </div>
      ));
  const courseGrades4Avg = (regNo, session, semester) =>
    rows
      .filter(
        (item) =>
          item.regNo === regNo &&
          item.session === session &&
          item.semester === semester
      )
      .map(
        (item) =>
          Number(item.score1) +
          Number(item.score2) +
          Number(item.score3) +
          Number(item.score4) +
          Number(item.score5) +
          Number(item.score6) +
          Number(item.ass) +
          Number(item.att) +
          Number(item.em)
      );
  React.useEffect(() => {
    const retrieveExamRes = async () => {
      try {
        const response = await fetch(`${API_URL}/examResults`);
        if (!response.ok) {
          throw new Error(response.status);
        }
        const res = await response.json();
        setRows(res);
      } catch (e) {}
    };
    retrieveExamRes();
  }, [rows]);
  const regNoLst = rows && rows.map(({ regNo }) => regNo);
  return (
    <div>
      <BootstrapDialog
        fullScreen
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openResultSummary}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {session}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Stack direction="row" spacing={1}>
            <TableContainer
              component={Paper}
              sx={{ background: "rgba(229,255,239,1)", pb: 3 }}
            >
              <p style={{ maxWidth: 150, margin: "auto", marginTop: 10 }}>
                First Semester
              </p>
              <Table aria-label="Results Table">
                <TableHead>
                  <TableRow>
                    {" "}
                    <TableCell sx={{ fontWeight: "bold" }}>Reg No</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      TMD<span style={{ color: "white" }}>_</span>601
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      TMD<span style={{ color: "white" }}>_</span>603
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      TMD<span style={{ color: "white" }}>_</span>605
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      TMD<span style={{ color: "white" }}>_</span>607
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      TMD<span style={{ color: "white" }}>_</span>609
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>AVG</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {regNoLst && regNoLst.length !== 0
                    ? [...new Set(regNoLst)].map((regNo) => (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="left">{regNo}</TableCell>
                          <TableCell align="left">
                            {courseGrades(regNo, session, "First Semester")[0]}
                          </TableCell>
                          <TableCell align="left">
                            {courseGrades(regNo, session, "First Semester")[1]}
                          </TableCell>
                          <TableCell align="left">
                            {courseGrades(regNo, session, "First Semester")[2]}
                          </TableCell>
                          <TableCell align="left">
                            {courseGrades(regNo, session, "First Semester")[3]}
                          </TableCell>
                          <TableCell align="left">
                            {courseGrades(regNo, session, "First Semester")[4]}
                          </TableCell>

                          <TableCell align="left">
                            {Math.round(
                              (mayokun(
                                courseGrades4Avg(
                                  regNo,
                                  session,
                                  "First Semester"
                                )[0]
                              ) +
                                mayokun(
                                  courseGrades4Avg(
                                    regNo,
                                    session,
                                    "First Semester"
                                  )[1]
                                ) +
                                mayokun(
                                  courseGrades4Avg(
                                    regNo,
                                    session,
                                    "First Semester"
                                  )[2]
                                ) +
                                mayokun(
                                  courseGrades4Avg(
                                    regNo,
                                    session,
                                    "First Semester"
                                  )[3]
                                ) +
                                mayokun(
                                  courseGrades4Avg(
                                    regNo,
                                    session,
                                    "First Semester"
                                  )[4]
                                ) +
                                mayokun(
                                  courseGrades4Avg(
                                    regNo,
                                    session,
                                    "First Semester"
                                  )[5]
                                ) +
                                mayokun(
                                  courseGrades4Avg(
                                    regNo,
                                    session,
                                    "First Semester"
                                  )[6]
                                ) +
                                mayokun(
                                  courseGrades4Avg(
                                    regNo,
                                    session,
                                    "First Semester"
                                  )[7]
                                ) +
                                mayokun(
                                  courseGrades4Avg(
                                    regNo,
                                    session,
                                    "First Semester"
                                  )[8]
                                )) /
                                9
                            )}
                          </TableCell>
                        </TableRow>
                      ))
                    : "No Record"}
                </TableBody>
              </Table>
            </TableContainer>

            <TableContainer
              component={Paper}
              sx={{ background: "rgba(229,255,239,1)", pb: 3 }}
            >
              <p style={{ maxWidth: 150, margin: "auto", marginTop: 10 }}>
                Second Semester
              </p>
              <Table aria-label="Results Table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      TMD<span style={{ color: "white" }}>_</span>602
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      TMD<span style={{ color: "white" }}>_</span>604
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      TMD<span style={{ color: "white" }}>_</span>606
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      TMD<span style={{ color: "white" }}>_</span>608
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      TMD<span style={{ color: "white" }}>_</span>610
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>AVG</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {regNoLst && regNoLst.length !== 0
                    ? [...new Set(regNoLst)].map((regNo) => (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          {/* <TableCell align="left">{regNo}</TableCell> */}

                          <TableCell align="left">
                            {courseGrades(regNo, session, "Second Semester")[0]}
                          </TableCell>
                          <TableCell align="left">
                            {courseGrades(regNo, session, "Second Semester")[1]}
                          </TableCell>
                          <TableCell align="left">
                            {courseGrades(regNo, session, "Second Semester")[2]}
                          </TableCell>
                          <TableCell align="left">
                            {courseGrades(regNo, session, "Second Semester")[3]}
                          </TableCell>
                          <TableCell align="left">
                            {courseGrades(regNo, session, "Second Semester")[4]}
                          </TableCell>

                          <TableCell align="left">
                            {Math.round(
                              (mayokun(
                                courseGrades4Avg(
                                  regNo,
                                  session,
                                  "Second Semester"
                                )[0]
                              ) +
                                mayokun(
                                  courseGrades4Avg(
                                    regNo,
                                    session,
                                    "Second Semester"
                                  )[1]
                                ) +
                                mayokun(
                                  courseGrades4Avg(
                                    regNo,
                                    session,
                                    "Second Semester"
                                  )[2]
                                ) +
                                mayokun(
                                  courseGrades4Avg(
                                    regNo,
                                    session,
                                    "Second Semester"
                                  )[3]
                                ) +
                                mayokun(
                                  courseGrades4Avg(
                                    regNo,
                                    session,
                                    "Second Semester"
                                  )[4]
                                ) +
                                mayokun(
                                  courseGrades4Avg(
                                    regNo,
                                    session,
                                    "Second Semester"
                                  )[5]
                                ) +
                                mayokun(
                                  courseGrades4Avg(
                                    regNo,
                                    session,
                                    "Second Semester"
                                  )[6]
                                ) +
                                mayokun(
                                  courseGrades4Avg(
                                    regNo,
                                    session,
                                    "Second Semester"
                                  )[7]
                                ) +
                                mayokun(
                                  courseGrades4Avg(
                                    regNo,
                                    session,
                                    "Second Semester"
                                  )[8]
                                )) /
                                9
                            )}
                          </TableCell>
                        </TableRow>
                      ))
                    : "No Record"}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
