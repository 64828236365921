import * as React from "react";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { TextField, Select } from "formik-mui";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { FormHelperText } from "@mui/material";
import Stack from "@mui/material/Stack";
import { API_URL } from "../utils/api";
import { grade } from "../utils/grade";
import { useFeedBack, FeedBack } from "./FeedBack";
import { useStateValue } from "../utils/app-state-context";
import { useNavigate } from "react-router-dom";


export default function ResultsForm() {
  const navigate = useNavigate();
  const { openSnack, setOpenSnack, msg, setMsg, handleCloseSnackbar } =
    useFeedBack();
  const [{ editRecord, editMode }, dispatch] = useStateValue();
  // React.useEffect(() => {
  //   return () => {
  //     dispatch({
  //       type: "EDIT-RESULT",
  //       payload: {
  //         editRecord: undefined,
  //         editMode: false,
  //       },
  //     });
  //   };
  // }, []);
  return (
    <React.Fragment>
      <FeedBack
        openSnack={openSnack}
        msg={msg}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <Box
        sx={{
          maxWidth: 500,
          p: 1,
          margin: "auto",
          marginTop: 5,
          borderRadius: 5,
        }}
      >
        <Formik
          enableReinitialize={true}
          initialValues={{
            session: (editRecord && editRecord.session) || "",
            semester: (editRecord && editRecord.semester) || "",
            centre: (editRecord && editRecord.centre) || "",
            courseCode: (editRecord && editRecord.courseCode) || "",
            regNo: (editRecord && editRecord.regNo) || "",
            score1: (editRecord && editRecord.score1) || "",
            score2: (editRecord && editRecord.score2) || "",
            score3: (editRecord && editRecord.score3) || "",
            score4: (editRecord && editRecord.score4) || "",
            score5: (editRecord && editRecord.score5) || "",
            score6: (editRecord && editRecord.score6) || "",
            ass: (editRecord && editRecord.ass) || "",
            att: (editRecord && editRecord.att) || "",
            em: (editRecord && editRecord.em) || "",
          }}
          validationSchema={Yup.object({
            session: Yup.string().required("*session is required"),
            semester: Yup.string().required("*semester is required"),
            centre: Yup.string().required("*centre is required"),
            courseCode: Yup.string().required("*course code is required"),
            regNo: Yup.string().required("*regNo is required"),
            // score1: Yup.string().required("*score1 is required"),
            // score2: Yup.string().required("*score2 is required"),
            // score3: Yup.string().required("*score3 is required"),
            // score4: Yup.string().required("*score4 is required"),
            // score5: Yup.string().required("*score5 is required"),
            // score6: Yup.string().required("*score6 is required"),
            // ass: Yup.string().required("*ass is required"),
            // att: Yup.string().required("*att is required"),
            // em: Yup.string().required("*em is required"),
          })}
          onSubmit={async (values, { setSubmitting, setFieldValue }) => {
            setSubmitting(true);
            const path = editMode
              ? `examResults/${editRecord.id}`
              : `examResults`;
            try {
              const response = await fetch(`${API_URL}/${path}`, {
                method: editMode ? "PUT" : "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  session: values.session,
                  semester: values.semester,
                  centre: values.centre,
                  courseCode: values.courseCode,
                  regNo: values.regNo,
                  score1: values.score1,
                  score2: values.score2,
                  score3: values.score3,
                  score4: values.score4,
                  score5: values.score5,
                  score6: values.score6,
                  ass: values.ass,
                  att: values.att,
                  em: values.em,
                }),
              });
              if (!response.ok) {
                throw new Error(response.status);
              }
              const res = await response.json();
              if (res) {
                setMsg(`Result Submitted Successfully. `);
                setOpenSnack(true);
                // setFieldValue("session", "");
                // setFieldValue("semester", "");
                // setFieldValue("centre", "");
                // setFieldValue("courseCode", "");
                setFieldValue("regNo", "");
                setFieldValue("score1", "");
                setFieldValue("score2", "");
                setFieldValue("score3", "");
                setFieldValue("score4", "");
                setFieldValue("score5", "");
                setFieldValue("score6", "");
                setFieldValue("ass", "");
                setFieldValue("att", "");
                setFieldValue("em", "");
              }
              setSubmitting(false);
            } catch (e) {
              console.error("Error adding document: ", e);
              setSubmitting(false);
            }
          }}
        >
          {({
            submitForm,
            isSubmitting,
            values,
            handleChange,
            setFieldValue,
          }) => (
            <Form>
              <Box
                sx={{
                  p: 2,
                  borderRadius: 5,
                  width: "100%",
                  //   background: "rgba(229,255,239)",
                  margin: "auto",
                  // background:
                  //   "linear-gradient(135deg, rgba(0,127,128,0.47942927170868344) 7%, rgba(118,148,216,0.4962359943977591) 35%, rgba(162,231,227,0.4990371148459384) 60%, rgba(229,255,239,1) 93%)",
                }}
              >
                {" "}
                {isSubmitting && <LinearProgress sx={{ my: 2 }} />}
                <Stack
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                  style={{ margin: 10 }}
                >
                  <Button onClick={()=>navigate("/results-dashboard")}>Back</Button>
                  <Typography
                    component="div"
                    noWrap
                    style={{ marginTop: "20", marginBottom: "20" }}
                  >
                    Enter Results &nbsp;&nbsp;&nbsp;
                    <Button
                      sx={{ textTransform: "none" }}
                      onClick={() => {
                        setFieldValue("session", "");
                        setFieldValue("semester", "");
                        setFieldValue("centre", "");
                        setFieldValue("courseCode", "");
                        setFieldValue("regNo", "");
                        setFieldValue("score1", "");
                        setFieldValue("score2", "");
                        setFieldValue("score3", "");
                        setFieldValue("score4", "");
                        setFieldValue("score5", "");
                        setFieldValue("score6", "");
                        setFieldValue("ass", "");
                        setFieldValue("att", "");
                        setFieldValue("em", "");
                      }}
                    >
                      Reset Forms
                    </Button>
                  </Typography>
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="regNo"
                      size="small"
                      label="Select Student"
                      variant="outlined"
                    >
                      <MenuItem value={"ADETEYE Temitope Adetayo"}>
                        ADETEYE Temitope Adetayo
                      </MenuItem>
                      <MenuItem value={"ELUMARO Ola Adesoji"}>
                        ELUMARO Ola Adesoji
                      </MenuItem>
                      <MenuItem value={"ADAMU Christian Oshomane"}>
                        ADAMU Christian Oshomane
                      </MenuItem>
                    </Field>
                  </FormControl>
                  <Field
                    component={TextField}
                    fullWidth
                    name="session"
                    type="text"
                    size="small"
                    label="Session"
                    variant="outlined"
                  />
                  <FormHelperText error></FormHelperText>
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="semester"
                      size="small"
                      label="Semester"
                      variant="outlined"
                    >
                      <MenuItem value={"First Semester"}>
                        First Semester
                      </MenuItem>
                      <MenuItem value={"Second Semester"}>
                        Second Semester
                      </MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="centre"
                      size="small"
                      label="Centre"
                      variant="outlined"
                    >
                      <MenuItem value={"Ile Ife"}>Ile Ife</MenuItem>
                      <MenuItem value={"Lagos"}>Lagos</MenuItem>
                      <MenuItem value={"Abuja"}>Abuja</MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="courseCode"
                      size="small"
                      label="Course Title"
                      variant="outlined"
                    >
                      <MenuItem value={"601"}>
                        TMD 601: INTRO. TO SCIENCE & TECHNOLOGY POLICY
                      </MenuItem>
                      <MenuItem value={"603"}>
                        TMD 603: TECHNOLOGY STRATEGY AND CORPORATE PLANNING
                      </MenuItem>
                      <MenuItem value={"605"}>
                        TMD 605: RESEARCH METHODOLOGY AND STATISTICS
                      </MenuItem>
                      <MenuItem value={"607"}>
                        TMD 607: TECHNOLOGICAL ENTREPRENEURSHIP
                      </MenuItem>
                      <MenuItem value={"609"}>
                        TMD 609: INTRO. TO QUANTITATIVE TECHNIQUES IN DECISION
                        MAKING
                      </MenuItem>
                      <MenuItem value={"602"}>
                        TMD 602: INTRO. TO PRODUCTION & OPERATION MANAGEMENT
                      </MenuItem>
                      <MenuItem value={"604"}>
                        TMD 604: MANAGEMENT OF R&D AND INNOVATION SYSTEM
                      </MenuItem>
                      <MenuItem value={"606"}>
                        TMD 606: INTRO. TO ACCOUNTING & ENGINEERING ECONOMY
                      </MenuItem>
                      <MenuItem value={"608"}>
                        TMD 608: TOTAL QUALITY MANAGEMENT & NEW GAMES IN
                        TECHNOLOGY
                      </MenuItem>
                      <MenuItem value={"610"}>
                        TMD 610: FUNDAMENTALS OF COMPUTER & INFORMATION
                        TECHNOLOGY: PROJECT
                      </MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={TextField}
                      fullWidth
                      name="score1"
                      type="number"
                      size="small"
                      label="Score 1"
                      variant="outlined"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={TextField}
                      fullWidth
                      name="score2"
                      type="number"
                      size="small"
                      label="Score 2"
                      variant="outlined"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={TextField}
                      fullWidth
                      name="score3"
                      type="number"
                      size="small"
                      label="Score 3"
                      variant="outlined"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={TextField}
                      fullWidth
                      name="score4"
                      type="number"
                      size="small"
                      label="Score 4"
                      variant="outlined"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={TextField}
                      fullWidth
                      name="score5"
                      type="number"
                      size="small"
                      label="Score 5"
                      variant="outlined"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={TextField}
                      fullWidth
                      name="score6"
                      type="number"
                      size="small"
                      label="Score 6"
                      variant="outlined"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    Exam Total:{" "}
                    {Number(values.score1) +
                      Number(values.score2) +
                      Number(values.score3) +
                      Number(values.score4) +
                      Number(values.score5) +
                      Number(values.score6) || ""}
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={TextField}
                      fullWidth
                      name="ass"
                      size="small"
                      label="Assignment"
                      variant="outlined"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </FormControl>

                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={TextField}
                      fullWidth
                      name="att"
                      size="small"
                      label="Attendance"
                      variant="outlined"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={TextField}
                      fullWidth
                      name="em"
                      size="small"
                      label="Em"
                      variant="outlined"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    Overall Total:{" "}
                    {Number(values.score1) +
                      Number(values.score2) +
                      Number(values.score3) +
                      Number(values.score4) +
                      Number(values.score5) +
                      Number(values.score6) +
                      Number(values.ass) +
                      Number(values.att) +
                      Number(values.em) || ""}{" "}
                    &nbsp;{" "}
                    {((Number(values.score1) ||
                      Number(values.score2) ||
                      Number(values.score3) ||
                      Number(values.score4) ||
                      Number(values.score5) ||
                      Number(values.score6) ||
                      Number(values.ass) ||
                      Number(values.att) ||
                      Number(values.em)) &&
                      "Grade:" +
                        " " +
                        grade(
                          Number(values.score1) +
                            Number(values.score2) +
                            Number(values.score3) +
                            Number(values.score4) +
                            Number(values.score5) +
                            Number(values.score6) +
                            Number(values.ass) +
                            Number(values.att) +
                            Number(values.em)
                        )) ||
                      ""}
                  </FormControl>
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                  style={{ margin: 10 }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={submitForm}
                    style={{
                      margin: 20,
                      background: "#0C2340",
                      paddingLeft: "40px",
                      paddingRight: "40px",
                      textTransform: "none",
                    }}
                  >
                    {isSubmitting ? (
                      <span style={{ color: "yellow" }}>... submitting</span>
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </Stack>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </React.Fragment>
  );
}
